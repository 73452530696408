import React, { Component, useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import { ChakraProvider } from '@chakra-ui/react';
import useWindowDimensions from "../components/WindowDimensionHook/useWindowDimensions";
import Footer from '../components/Footer';

const MobileNavigationProject = () => {
  const { currentWindowWidth } = useWindowDimensions();
  const [isSmallerWindow, setIsSmallerWindow] = useState(false);
  const minimumWindowWidthForLargerLayout = 770;
  useEffect(() => {
    if (currentWindowWidth < minimumWindowWidthForLargerLayout) {
      setIsSmallerWindow(true);
    } else {
      setIsSmallerWindow(false);
    }
  }, [currentWindowWidth]);
  return (
    <ChakraProvider>
      <Navbar />
      <div className="mainDiv mx-auto text-justified">
        {/* header */}
        <section className="headerBody">
          <div className="backgroundImage" />
          <div className="divHeader">
            <h2 className="headerHaeding">ALEXANDER PRESS STREET</h2>
            <p className="headerSubHeading">Mobile Navigation Project</p>
          </div>
        </section>
        {/* section1 */}
        <div className="section_body_1 d-flex flex-column flex-md-row mx-auto align-items-center justify-content-between pe-0 pe-md-4 px-0 px-md-4 overflow-hidden">
          <div className="col-10 p-0 p-md-3 pr-0 pr-xl-5 col-md-6 pe-0 pe-xl-5 mb-0 mb-xl-5">
            <div className="text_heading">ABOUT THE PROJECT</div>
            <div className="mt-2 pic-margin-left">
              <img className="img-fluid imgSp" width="347px" height="144px" src="/PicturesPage1/section1Logo.jpg" />
            </div>
            <div className="text_simple mt-2">
              The client, Alexander Street Press, is a publisher of online videos
              for scholarly research, teaching, and studying.
            </div>
          </div>
          <div className="mt-0 pb-0 pb-xl-3 pt-0 px-0 px-md-5 mt-md-0 col-10 col-md-7">
            <div>
              <div className="text_heading mt-3 mt-md-0">PROJECT GOALS</div>
              <div className="text_simple mt-2 pr-3">
                The goal was to reorganize alexanderstreet.com navigation to make
                it more user-friendly and optimized for mobile devices by
                conducting user research and prototype testing.
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-4 gap-4 w-100">
              <div className="col-12 col-md-5 text-start p-0 text-left">
                <div className="text_heading">OUTCOMES</div>
                <div className="text_simple mt-2 pe-0 pe-md-3 pr-3">
                  User research <br /> Interactive prototype <br /> User testing
                </div>
              </div>
              <div className="col-12 p-0 px-md-2 mt-3 mt-md-0 col-md-7">
                <div className="text_heading">MY ROLE</div>
                <div className="text_simple mt-2 pr-1">
                  I conducted user testing, created personas, designed sketches,
                  and worked on prototype development
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section2 */}
        <div className="bg-change">
          <div className="section_body_2 d-flex flex-column mx-auto px-3 px-lg-0">
            <div className="d-flex justify-content-center text_heading">
              RESEARCH PROCESS
            </div>
            <div className="mt-3" style={{ overflowX: 'auto', overflowY: 'hidden', fontSize: '18px', fontWeight: 400 }}>
              <div style={{ width: '1022px', height: '120px' }} className="position-relative mt-2">
                <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '15px', lineHeight: '1em' }}>
                  Stakeholder Interview
                </div>
                <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '205px', lineHeight: '1em' }}>
                  User Research
                </div>
                <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '386px', lineHeight: '1em' }}>
                  Personas Design
                </div>
                <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '560px', lineHeight: '1em' }}>
                  Scetching &amp; Ideation
                </div>
                <div className="position-absolute text-center" style={{ top: '70px', width: '120px', left: '720px', lineHeight: '1em' }}>
                  Prototyping
                </div>
                <div className="position-absolute text-center" style={{ top: '55px', width: '155px', left: '875px', lineHeight: '1em' }}>
                  Prototype Evaluation
                </div>
                <img width="100%" height=" 34px" className="z-0" src="/PicturesPage1/Line-removebg-preview.png" />
              </div>
            </div>
          </div>
        </div>
        {/* section3 */}
        <div className="section_body_1 d-flex flex-column align-items-center mx-auto px-3 px-lg-0">
          <div className="text-center text_simple">
            Our first step in this study was an interview with Alexander
            PressStreet’s CEO. This helped us understand the upcoming project, its
            goals, and the company’s resources and limitations.
          </div>
          <div className="text_heading mt-3">STAKEHOLDER INTERVIEW INSIGHTS</div>
          <div className="d-flex justify-content-between mt-5 flex-column flex-md-row mx-auto align-items-center w-100 pl-4" style={{ wordSpacing: '0.3em' }}>
            <div className="w-100 w-md-50">
              <div className="d-flex">
                <div className="d-flex align-items-center imgSp2" style={{ width: '22%' }}>
                  <img width="70px" height="70px" className={`imgOpacity ${!isSmallerWindow ? "imgSp" : ""} opacity60`} src="/PicturesPage1/stakeHolder1.jpg" />
                </div>
                <div className="text_simple ml-2 ml-md-0 text-justified" style={{ width: '65%' }}>
                  This resulted in an excellent tagging system for videos and a
                  large reserve of metadata. There are possible A.I. integrations,
                  but the details and specifics are not currently available.
                </div>
              </div>
              <div className="d-flex pt-5 pt-md-3 align-items-center">
                <div className="d-flex align-items-center" style={{ width: '22%' }}>
                  <img width="55px" height="55px" className={`imgOpacity ${!isSmallerWindow ? "imgSp" : ""} opacity60`} src="/PicturesPage1/stakeHolder2.jpg" />
                </div>
                <div className="text_simple ml-2 ml-md-0" style={{ width: '65%' }}>
                  The client is not interested in pivoting to a skill-teaching
                  website.
                </div>
              </div>
            </div>
            <div className="w-100 w-md-50 d-flex flex-column">
              <div className="d-flex mt-4 align-items-start align-items-md-start justify-content-md-end">
                <div className="d-flex align-items-center" style={{ width: '23%' }}>
                  <img width="62px" height="62px" className={`imgOpacity ${!isSmallerWindow ? "imgSp" : ""} opacity60`} src="/PicturesPage1/stakeHolder3.jpg" />
                </div>
                <div className="text_simple ml-2 ml-md-0" style={{ width: '65%' }}>
                  Alexander Street is looking to expand its services
                  internationally, but the current focus is on the U.S. and
                  possibly Mexico.
                </div>
              </div>
              <div className="d-flex mt-4 align-items-start align-items-md-start justify-content-md-end">
                <div className="d-flex align-items-center" style={{ width: '23%' }}>
                  <img width="82px" height="71px" className={`imgOpacity ${!isSmallerWindow ? "imgSp" : ""} opacity60`} src="/PicturesPage1/stakeHolder4.jpg" />
                </div>
                <div className="text_simple ml-2 ml-md-0" style={{ width: '65%' }}>
                  An overall professional style should still be maintained, but
                  anew brand or slight changes are acceptable.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section4 */}
        <div className="bg-change">
          <div className="section_body_2 d-flex flex-column mx-auto px-3 px-lg-0">
            <div className="text_heading text-center">USER RESEARCH</div>
            <div className="text-center text_simple mt-4 px-2 px-lg-0">
              Questions and tasks were designed from various perspectives to
              better capture which functions are important when users are
              searching for new videos.
            </div>
            <div className="d-flex flex-column flex-md-row item mt-5">
              <div className="w-100">
                <div className="d-flex gap-4" style={{ width: '85%' }}>
                  <div className="col-5 col-md-4">
                    <img className="img-fluid imgOpacity" width="134px" height="108px" src="/PicturesPage1/InterView.jpeg" />
                  </div>
                  <div className="col-8 p-0 m-0 text_simple">
                    <div style={{ color: '#373737' }}><b>Interview</b></div>
                    <div>
                      We conducted interviews to collect information about
                      users’interactions via mobile while watching the video and
                      to get a better idea of the functions they need.
                    </div>
                    <div>
                      <ul className="mt-3 p-0">
                        <li>
                          <span style={{ fontWeight: 700 }}>Timeline: </span>
                          35–45 minutes
                        </li>
                        <li>
                          <span style={{ fontWeight: 700 }}>13 questions</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {!isSmallerWindow && (
                  <div className='col-11'>
                    <ul className="m-0 p-3 py-2 text_simple">
                      <li>
                        How do you find a new video? What is your choice based on?
                      </li>
                      <li>Which video website do you prefer the most, and why?</li>
                      <li>
                        Which functions do you use while watching videos?
                        (Subtitles, volume, sharing, speed settings.)
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-12 mt-4 mt-md-0 col-md-5 ps-0 mb-4">
                <div className="d-flex gap-3 gap-md-4">
                  <div className="col-4 col-md-4">
                    <img className="img-fluid imgOpacity" width="137px" height="135px" src="/PicturesPage1/observation.png" />
                  </div>
                  <div className="col-8 p-0 m-0 pr-3 pr-md-0 pl-md-0 text_simple">
                    <div style={{ color: '#373737' }}><b>Observation</b></div>
                    <div>
                      To understand how users interact with mobile devices and how
                      they navigate, we observed how people completed three tasks.
                    </div>
                    <div>
                      <ul className="mt-3 p-0">
                        <li>
                          <span style={{ fontWeight: 700 }}>Timeline: </span>
                          15–20 minutes
                        </li>
                        <li>
                          <span style={{ fontWeight: 700 }}>3 questions</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {!isSmallerWindow && (
                  <div className="text_simple mt-2">
                    We asked users to find a specific video without using a search
                    and to share the video with a friend, and we observed how they
                    watched a long video with limited time.
                  </div>
                )}
              </div>
            </div>
            <div className="text_heading mt-4 mt-md-0 text-center">FINDINGS</div>
            <div>
              <div className="text_simple p-3 p-md-4 mt-3 text-left">
                The user research results guided the direction of our prototype
                and became a background for the first sketches. The most important
                ideas we gained from the interview were these:
              </div>
              <div className="d-flex flex-column align-items-center flex-md-row">
                <div className="col-12 col-md-7 p-0 ">
                  <ul className="m-0 text_simple col-12 col-md-10" style={{ fontWeight: 300, lineHeight: 1 }}>
                    <li>
                      Users are constantly looking for the next video to watch
                    </li>
                    <li>
                      They dislike needing to register mid-task and may not
                      continue if that is required
                    </li>
                    <li>
                      Users prefer simple, patterned navigation for mobile video
                      apps
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-5 mt-2 mt-md-0 text_simple">
                  <ul className="m-0 py-2" style={{ fontWeight: 300, lineHeight: '1.2' }}>
                    <li>
                      Users like to have full control of their viewing experiences
                    </li>
                    <li>
                      Users look for creative ways to find out what videos are
                      about because watching a video is overwhelming and
                      time-consuming.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section5 */}
        <div className="section_body_1 d-flex px-0 px-md-3 flex-column align-items-center mx-auto">
          <div className="text_heading">PERSONA DESIGN</div>
          <div className="text_simple p-3 p-md-0 px-2 px-lg-0 mt-4">
            Through our user research, we identified three types of people who
            might be interested in watching educational videos through mobile
            apps. Understanding these users and their motivations and requirements
            helped us start the design process. These audiences are important to
            Alexander Street because its marketing department is considering
            collaboration with colleges and bloggers.
          </div>
          <div className="d-flex pl-0 pl-md-4 w-100 justify-content-between align-items-center flex-md-row flex-column">
            <div className="m-0 p-0 mt-4 sepcClass3">
              <div className="text_heading">Allan, school professor</div>
              <div className="mt-3">
                <img className="img-fluid imgSp  mb-3" width="210px" height="210px" src="/PicturesPage1/Professor.jpg" />
                <div className="m-0 p-0 para text_simple" style={{ fontWeight: 400, lineHeight: 1 }}>
                  <div>Age: 54</div>
                  <div>Gender: Male</div>
                  <div>Occupation: Professor</div>
                  <div>Was born in Long Island, USA</div>
                </div>
              </div>
              <div className="para mt-4 text_simple" style={{ lineHeight: '1.2' }}>
                <div style={{ fontWeight: 400 }}>NEEDS/GOALS</div>
                <div className="textPersona">Class preparation</div>
                <div className="textPersona">Sharing materials with students</div>
                <div className="textPersona">Research papers publication</div>
              </div>

              <img class="img-fluid mt-3" width="250px" src='/PicturesPage1/slider_1.png' />
            </div>
            <div className="m-0 p-0 mt-4 sepcClass3">
              <div className="text_heading">Pinger, student</div>
              <div className="mt-3">
                <img className="img-fluid imgSp mb-3" width="210px" height="210px" src="/PicturesPage1/GlassesGuy (1).jpg" />
                <div className="m-0 p-0 para text_simple" style={{ lineHeight: 1 }}>
                  <div style={{ fontWeight: 400 }}>Age: 24</div>
                  <div style={{ fontWeight: 400 }}>Gender: Male</div>
                  <div style={{ fontWeight: 400 }}>Occupation: Student</div>
                  <div style={{ fontWeight: 400 }}>Lives in Beijing, China</div>
                </div>
              </div>
              <div className="para mt-4 text_simple" style={{ lineHeight: '1.2' }}>
                <div style={{ fontWeight: 400 }}>NEEDS/GOALS</div>
                <div className="textPersona">Watching design tutorials</div>
                <div className="textPersona">Language learning</div>
                <div className="textPersona">Entertainment</div>
              </div>
              <img class="img-fluid mt-3" width="250px" src='/PicturesPage1/slider_2.png' />
            </div>
            <div className="m-0 p-0 mt-4 sepcClass3">
              <div className="text_heading">Renae, blogger</div>
              <div className="mt-3">
                <img className="img-fluid imgSp mb-3" width="210px" height="210px" src="/PicturesPage1/Lady (1).jpg" />
                <div className="m-0 p-0 para text_simple" style={{ fontWeight: 400, lineHeight: 1 }}>
                  <div>Age: 29</div>
                  <div>Gender: Female</div>
                  <div>Occupation: Blogger</div>
                  <div>Was born in Paris, France</div>
                </div>
              </div>
              <div className="para mt-4 text_simple" style={{ lineHeight: '1.2' }}>
                <div style={{ fontWeight: 400 }}>NEEDS/GOALS</div>
                <div className="textPersona">Good navigation</div>
                <div className="textPersona">Language learnig</div>
                <div className="textPersona">Social media content update</div>
              </div>
              <img class="img-fluid mt-3" width="250px" src='/PicturesPage1/slider_3.png' />
            </div>
          </div>
        </div>
        {/* section6 */}
        <div className="bg-change px-2 px-xl-5">
          <div className="section_body_2 d-flex flex-column align-items-center mx-auto px-lg-0 px-3">
            <div className="text_heading">SKETCHING &amp; IDEATION</div>
            <div className="d-flex w-100 flex-column flex-md-row align-items-center align-items-md-start justify-content-between mt-4">
              <div>
                <img className="img-fluid" width="485px" height="314px" src="/PicturesPage1/sketching.jpg" />
              </div>
              <div className="mt-5 sk1 d-flex align-items-center  align-items-md-start flex-column">
                <div className="text_heading lineHeight text-center text-lg-left">
                  Prioritizing the features based on user research
                </div>
                <div className="text_simple mt-4">Three main tabs were designed:</div>
                <div>
                  <ul className="m-0 p-0 mt-3 text_simple">
                    <li>Recommended/Home (trending)</li>
                    <li>My Videos (saved for later/liked videos)</li>
                    <li>Catalogs (new videos by categories)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section7 */}
        <div className="section_body_1 d-flex flex-column align-items-center mx-auto px-3 px-lg-3">
          <div className="text_heading">DESIGN DECISIONS</div>
          <div className="d-flex mt-4 mt-md-0 flex-column align-items-center align-items-md-start justify-content-md-between flex-md-row">
            <div className="d-flex flex-column-reverse flex-md-column w45 specClassf3">
              <div>
                <div className="text_heading mt-3 mt-md-0">Bottom Navigation</div>
                <div className="text_simple mt-3">
                  Before deciding where to place the main navigation tools, we
                  analyzed reach zones for average and plus-sized mobile devices.
                  A navigation bar at the bottom makes it easier for users to
                  reach the menu icon.
                </div>
              </div>
              <div className="para d-flex flex-column justify-content-end align-items-end specClassf31">
                <img className="img-fluid" width="415px" height="430px" src="/PicturesPage1/treeStucture.jpg" />
              </div>
            </div>
            <div className="w43">
              <div className="para d-flex flex-column justify-content-center">
                <img className="img-fluid" width="428px" height="416px" src="/PicturesPage1/bottomnav.jpg" />
                <p className="para w-100 text-center" style={{ marginTop: "-20px" }}>Reach Area Zones</p>
              </div>
              <div className="text_heading mt-0 mt-md-5">Tree Structure</div>
              <div className="text_simple mt-4">
                For the Catalogs page, we used a tree navigation system that
                allows access to a hierarchical video structure. This is a common
                approach to navigation and one that many people will remember from
                using Windows, using layers in Photoshop, or wading through large
                hierarchies of files.
              </div>
            </div>
          </div>
        </div>
        {/* section7 */}
        <div className="bg-change" style={{ wordSpacing: '0rem' }}>
          <div className="section_body_2 d-flex flex-column align-items-center justify-content-start mx-auto">
            <div className="text_heading">PROTOTYPE EVALUATION</div>
            <div className="d-flex flex-column align-items-center align-items-md-start flex-md-row justify-content-between">
              <div className="mt-5">
                <img width="529px" height="758px" className="img-fluid" src="/PicturesPage1/prototype.jpg" />
              </div>
              <div className="mt-5 w43">
                <div className="text_simple">
                  The prototype was tested by ten users to identify weak points
                  andnavigation problems.
                </div>
                <div className="text_heading mt-5 md-mt-2">Increase page consistency</div>
                <div className="text_simple mt-4">
                  Our prototype was inconsistent in some areas, making it
                  difficult for users to navigate. One major inconsistency was the
                  positioning of the header of each section in the navigation menu
                  (some pages had no headers).
                </div>
                <div className="text_simple mt-3">
                  <span style={{ fontWeight: 700 }}>SOLUTION –</span> Match the
                  header styles of all sections to make the layout on the app more
                  consistent. This will improve the visibility of the heading and
                  navigation bar and make for easier navigation.
                </div>
                <div className="text_heading" style={{ marginTop: '70px' }}>
                  Focus on educational content
                </div>
                <div className="text_simple mt-4">
                  Users need more information about a video before they will
                  select it for viewing. Educational videos should be accompanied
                  by detailed descriptions, but this is difficult to implement on
                  the small screen of the smartphone.
                </div>
                <div className="text_simple mt-4">
                  <span style={{ fontWeight: 700 }}>SOLUTION –</span> To let users
                  read detailed descriptions of videos more easily, a pop-up
                  window should appear with a description when the user clicks on
                  the video's title.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section_body_1 d-flex flex-column align-items-center mx-auto">
          <div className="text_simple lineHeight text-center">
            <div className="text_heading">FINAL PROTOTYPE</div>
            <a href="https://docs.google.com/presentation/d/1wXJcB_3HlXATr-tlLa4zI3lp5QckD4AJuyMXWwsMqQM/edit#slide=id.p">View interactive prototype</a>
          </div>
          <div className="mt-5">
            <img width="1106px" height="815px" className="img-fluid" src="/PicturesPage1/finalPrototype.jpg" />
          </div>
          <div className="mt-4 text_simple">
            <a href="https://docs.google.com/presentation/d/1wXJcB_3HlXATr-tlLa4zI3lp5QckD4AJuyMXWwsMqQM/edit#slide=id.p" style={{ textDecoration: 'underline' }}>Learn more about the project</a>
          </div>
        </div>

        <div className="bg-change px-2 px-lg-0">
          <div className="section_body_2 d-flex flex-column align-items-center mx-auto pt-3">
            <div className="text_heading mt-5">REFLECTIONS</div>
            <div>
              <ul className="m-0 p-0 mt-4 text_simple px-2 px-lg-0">
                <li>
                  The sketch was a little inefficient in terms of collaboration,
                  and we needed to switch to Adobe XD for smoother integration.
                </li>
                <li className="mt-3">
                  Visual design is an essential part of the overall experience,
                  especially when the function of the app depends heavily on the
                  information hierarchy and legibility of the content
                </li>
                <li className="mt-3">
                  By conducting thorough research on our users, we were able to
                  deliver final designs that were thoughtful, comprehensive, and
                  relevant to both business and user needs. Futher research is
                  needed to test new incrporated features based on user feedback.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ChakraProvider >
  )
}

export default MobileNavigationProject;