import {
  Center,
  Button,
  Text,
  useColorModeValue,
  Heading,
  Container,
} from "@chakra-ui/react";
import React from "react";
import "./styles.css";
import { blogPosts } from "./constants/constants";
const Blog = () => {
  return (
    <div style={{ backgroundColor: "#f7f7f7", padding: "60px 0" }}>
      <Container maxW={"7xl"} isLazy>
        <div className="container-fluid">
          <div
            py={4}
            className="justify-center"
            style={{ flex: 1.5, display: "flex", alignItems: Center }}
          >
            <Heading style={{ alignSelf: "center" }}>
              <Text className="main-heading" style={{ color: "#33b38d",fontFamily: "Helvetica Neue" }}>
                <p className="width-extra">Recent Publications</p>
              </Text>
            </Heading>
          </div>

          <Center id="blog" py={{ base: 0, lg: 8 }} style={{ flex: 2 }}>
            <section className="reveal" style={{ width: "100%" }}>
              <div className="section2-font margin-top2">
                <h6
                  style={{
                    color: "#33b38d",
                    fontWeight: "800",
                    fontFamily: "sans-serif",
                    fontSize:'18px'
                  }}
                >
                  Quantifying the Utility of Complexity and Feedback Loops in Casual Models for Decision Making 
                </h6>
                <h6 style={{ fontSize:'16px'}}>Korshakova, E., Marsh, J. K., & Kleinberg, S.</h6>
                <p style={{ color: "grey", fontStyle: "italic",fontSize:'16px' }}>
                  Annual Meeting of the Cognitive Science (CogSci) Society
                </p>
                <a href="https://formative.jmir.org/2022/9/e39274/PDF">
                  <button
                    style={{
                      color: "#33b38d",
                      border: "1px solid #33b38d",
                      padding: "2px 25px",
                      borderRadius: 5,
                      marginTop: 5,
                      fontSize: "15px",
                    }}
                  >
                    PDF
                  </button>
                </a>
                <br></br> <br></br>
                <h6
                  style={{
                    color: "#33b38d",
                    fontWeight: "800",
                    fontFamily: "sans-serif",
                    fontSize:'18px'
                  }}
                >
                  {" "}
                  Health Information Sourcing and Health Knowledge Quality: Repeated Cross-sectional Survey
                </h6>
                <h6 style={{ fontSize:'16px'}}>Korshakova, E., Marsh, J. K., & Kleinberg, S.</h6>
                <p style={{ color: "grey", fontStyle: "italic",fontSize:'16px' }}>
                  JMIR Formative Research
                </p>
                <a href="https://www.dropbox.com/s/fmf4f3uvzk9kpyf/HCXAI2022_paper_07.pdf?dl=0">
                  <button
                    style={{
                      color: "#33b38d",
                      border: "1px solid #33b38d",
                      padding: "2px 25px",
                      fontSize: "15px",
                      borderRadius: 5,
                      marginTop: 5,
                    }}
                  >
                    PDF
                  </button>
                </a>
                <br></br> <br></br>
                <h6
                  style={{
                    color: "#33b38d",
                    fontWeight: "800",
                    fontFamily: "sans-serif",
                    fontSize:'18px'
                  }}
                >
                  User Perceptions of an Intelligent Personal Assistant's
                  Personality: the Role of Interaction Context
                </h6>
                <h6 style={{ fontSize:'16px'}}>
                  Lopatovska, I., Korshakova, E., Brown, D., Li, Y., Min, J.,
                  Pasiak, A., & Zheng, K.
                </h6>
                <p style={{ color: "grey", fontStyle: "italic", fontSize:'16px' }}>
                  ACM Conference on Human Information Interaction and Retrieval
                </p>
                <a href="https://dl.acm.org/doi/pdf/10.1145/3406522.3446018">
                  <button
                    style={{
                      color: "#33b38d",
                      border: "1px solid #33b38d",
                      padding: "2px 25px",
                      fontSize: "15px",
                      borderRadius: 5,
                      marginTop: 5,
                    }}
                  >
                    PDF
                  </button>
                </a>
                <br></br> <br></br>
              </div>
            </section>
          </Center>
        </div>
      </Container>
    </div>
  );
};

export default Blog;
