import * as React from 'react'

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider, Divider } from '@chakra-ui/react'
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Contact from './components/Contact';
import TestimonialMain from './components/Testimonials';
import About from './components/About';
import Projects from './components/Projects';
import Blog from './components/Blog';
import EyeTrackingResearch from './pages/eyeTrackingResearch';
import DigitalAnalytics from './pages/digitalAnalytics';
import MobileNavigationProject from './pages/mobileNavigationProject';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />

        <Route path="/eye-tracking-research" element={<EyeTrackingResearch />} />
        <Route path="/digital-analytics" element={<DigitalAnalytics />} />
        <Route path="/mobile-navigation-project" element={<MobileNavigationProject />} />

      </Routes>
    </BrowserRouter>
  )
};


const Home = () => {
  return (
    <ChakraProvider>
      <div style={{ fontFamily: "Helvetica Neue",minWidth:"300px" }}>
        <Navbar />
        <Hero />
        <Blog />
        <TestimonialMain />
        <Projects />
        <Footer />
      </div>
    </ChakraProvider>
  )
};

export default App;
