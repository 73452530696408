import React, { Component } from 'react'

import { ChakraProvider, Container, Divider } from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


export default class DigitalAnalytics extends Component {
  render() {
    return (
      <ChakraProvider >
        <div style={{ fontFamily: 'Roboto' }}>
          <Navbar />
          <div className="mainDiv mx-auto">
            {/* header */}
            <section className="headerBody">
              <div className="backgroundImage2" />
              <div className="divHeader">
                <h2 className="headerHaeding">Art 21</h2>
                <p className="headerSubHeading">Digital Analytics</p>
              </div>
            </section>
            {/* section1 */}
            <div className="section_body_1 d-flex flex-column flex-md-row mx-auto align-items-center justify-content-between pe-2 px-lg-0 px-2 px-xl-0 overflow-hidden">
              <div className="col-10 col-md-6 p-0 px-0 px-md-3">
                <div className="text_heading">ABOUT THE PROJECT</div>
                <div style={{ marginLeft: '-5px' }} className="mt-2">
                  <img className="img-fluid imgSp" width="253px" height="154px" src="/PicturesPage2/header.png" />
                </div>
                <div className="text_simple mt-2 pr-0 pr-md-5">
                  Art21 is a well-reputed organization that presents thought-provoking
                  and sophisticated content on contemporary art. Its YouTube channel
                  in particular provides original videos on contemporary art and
                  artists and releases material exclusively online.
                </div>
              </div>
              <div className="pl-0 pl-md-5 mt-4 mt-md-0 col-10 col-md-6">
                <div>
                  <div className="text_heading">PROJECT GOALS</div>
                  <div className="text_simple mt-2">
                    The main goal was to analyze the audience, traffic sources,
                    geographical data, competitors, and SEO performance to provide
                    Art21 with recommendations for optimizing its video performance
                    and increasing the audience engagement.
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row mt-4 gap-4 w-100">
                  <div className="col-12 col-md-5 text-start p-0">
                    <div className="text_heading">OUTCOMES</div>
                    <div className="text_simple mt-2" style={{ textAlign: 'left' }}>
                      SEO optimization Audience research Traffic dashboard Competitors
                      analysis
                    </div>
                  </div>
                  <div className="col-12 p-0 px-md-3 mt-3 mt-md-0 col-md-8">
                    <div className="text_heading">MY ROLE</div>
                    <div className="text_simple mt-2 pr-0 pr-md-4">
                      I worked on the SEO, content, and audience analysis, data
                      visualization, and optimization recommendations development.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* section2 */}
            <div className="bg-change">
              <div className="section_body_2 d-flex flex-column px-md-3 mx-auto px-5 px-lg-0">
                <div className="d-flex justify-content-center text_heading">
                  RESEARCH PROCESS
                </div>
                <div className="mt-3 text-roboto" style={{ overflowX: 'auto', overflowY: 'hidden', fontSize: '18px', fontWeight: 400 }}>
                  <div style={{ width: '1022px', height: '120px' }} className="position-relative mt-2">
                    <div className="position-absolute text-center" style={{ top: '55px', width: '150px', left: '0px', lineHeight: '1em' }}>
                      Views Timeline Analysis
                    </div>
                    <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '205px', lineHeight: '1em' }}>
                      User Research
                    </div>
                    <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '386px', lineHeight: '1em' }}>
                      Personas Design
                    </div>
                    <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '558px', lineHeight: '1em' }}>
                      Scetching &amp; Ideation
                    </div>
                    <div className="position-absolute text-center" style={{ top: '55px', width: '120px', left: '720px', lineHeight: '1em' }}>
                      Prototyping
                    </div>
                    <div className="position-absolute text-center" style={{ top: '55px', width: '155px', left: '875px', lineHeight: '1em' }}>
                      Prototype Evaluation
                    </div>
                    <img width="100%" height=" 34px" className="z-0" src="PicturesPage1/Line-removebg-preview.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* section3 */}
            <div className="section_body_1 d-flex flex-column align-items-center mx-auto px-3 px-lg-0">
              <div className="text_heading mt-3 text-center text-md-left">METHODOLOGY</div>
              <div className="d-flex flex-column flex-md-row w-100 mt-3 mt-md-4 justify-content-between align-items-center">
                <div className="col-10 col-md-3">
                  <div className="text_heading mt-3 text-center text-md-left ">Tools</div>
                  <div className="text_simple mt-3 text-justify text-md-left">
                    YouTube Analytics Google Analytics YouTube Data Tools Google Data
                    Studio<br/> Tableau
                  </div>
                </div>
                <div className="col-10 col-md-5 pe-4 ps-3">
                  <div className="text_heading mt-3 text-center text-md-start text-center text-md-left">Data</div>
                  <div className="text_simple mt-3 text-justify text-md-left">
                    Video views, Traffic sources, Geographic traffic Video views,
                    Traffic sources, Keywords Benchmarking data: Art Assignment and
                    MoMA Youtube Analytics <br/> Benchmarking Youtube Data
                  </div>
                </div>
                <div className="col-10 col-md-3 mt-3 mt-md-0">
                  <div className="text_heading mt-3 text-center text-md-start text-center text-md-left">
                    How we used it
                  </div>
                  <div className="text_simple mt-3 text-justify text-md-left">
                    Art21 YouTube channel analysis Art21.org website analysis
                    Competitive Analysis Dashboard <br/> Analysis &amp; Visualization
                  </div>
                </div>
              </div>
            </div>
            {/* section4 */}
            <div className="bg-change">
              <div className="section_body_2 d-flex flex-column px-md-3 mx-auto px-3 px-lg-0">
                <div className="d-flex justify-content-center text_heading">
                  VIEW-TIMELINE ANALYSIS
                </div>
                <div style={{ fontWeight: 400, wordSpacing: '-0.1em' }} className="text_simple col-12 col-md-11 mt-4 lineHeight">
                  <span style={{ fontWeight: 700 }}>Finding 1.</span> Views are higher in
                  September and October than the rest of the year. The viewing peaks
                  are typically around Wednesday and on the release dates for new
                  videos.
                </div>
                <div>
                  <img width="10240px" height="277px" className="img-fluid mt-4" src="/PicturesPage2/graph-1.png" />
                </div>
                <div style={{ fontWeight: 400 }} className="text_simple mt-4 px-2">
                  <span style={{ fontWeight: 700 }}> Recommendation 1.</span> Keep
                  publishing videos regularly and frequently.
                </div>
                <div className="text_simple mt-4 px-2">
                  Art 21 has been releasing videos regularly, and its view timeline is
                  relatively stable. Our recommendation was to keep updating its
                  content. However, we didn’t recommend publishing more than one video
                  a day, because the chart shows that publishing more videos may not
                  increase user engagement much. Quality is more important than
                  quantity.
                </div>
              </div>
            </div>
            {/* section5 */}
            <div className="section_body_1 d-flex flex-column align-items-center mx-auto px-3 px-lg-0">
              <div className="text_heading mt-3">TRAFFIC SOURCE ANALYSIS</div>
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="graph_width p-1">
                  <div style={{ fontWeight: 400 }} className="text_simple mt-4 lineHeight pe-3">
                    <span style={{ fontWeight: 700 }}>Finding 2.</span> External traffic
                    makes up 31.8% of the traffic to Art 21’s YouTube channel.
                  </div>
                  <div style={{ fontWeight: 400 }} className="text_simple mt-4 lineHeight">
                    <span style={{ fontWeight: 700 }}>Recommendation 2.</span> Expand the
                    channel’s traffic from external URLs by using social media, the
                    website, and other art resources.
                  </div>
                  <div className="mt-3 text_simple" style={{ wordSpacing: '0.5em' }}>
                    According to the traffic source data, traffic from external URL's
                    still has room to grow. These traffic sources, such as Art21’
                    social media accounts and website, are potential platforms for
                    attracting users to the YouTube channel. Promoting videos by
                    posting the channel links on social media will be effective for
                    increasing traffic. Some art resources, such as college
                    recommendations and newsletters can also expand the audience.
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <img width="545px" height="336px" className="img-fluid" src="/PicturesPage2/circle1.png" />
                </div>
              </div>
            </div>
            {/* section6 */}
            <div className="bg-change">
              <div className="section_body_2 d-flex flex-column mx-auto px-3 px-lg-4">
                <div className="text_heading mt-3 text-center">AUDIENCE ANALYSIS</div>
                <div className="d-flex justify-content-center mt-2 align-items-center flex-column flex-md-row">
                  <div className="d-flex col-10 col-md-6 align-items-center mt-4 mt-md-0">
                    <div style={{ backgroundColor: 'transparent' }} className="bg-primary">
                      <img width="390px" height="321px" className="img-fluid" src="/PicturesPage2/circle2.png" />
                    </div>
                  </div>
                  <div className="graph_width2">
                    <div style={{ fontWeight: 400 }} className="text_simple mt-4 lineHeight">
                      <span style={{ fontWeight: 700 }}>Finding 3.</span> More than 70%
                      of viewers come from English-speaking countries.
                    </div>
                    <div style={{ fontWeight: 400 }} className="text_simple mt-4 lineHeight">
                      <span style={{ fontWeight: 700 }}>Recommendation 3.</span> Add
                      subtitles to translate some content for various audiences,
                      especially Korean viewers.
                    </div>
                    <div className="mt-3 text_simple" style={{ wordSpacing: '0.5em' }}>
                      Korea ranks seventh among countries in the channel’s audience,
                      but Art 21 videos don’t have Korean subtitles. Adding these
                      could be helpful for ­expanding the Korean audience. Asian
                      audiences, in general, are a promising line of expansion for Art
                      21. If possible, adding Chinese and Japanese subtitles will help
                      Art21 gain recognition among Asian audiences. We also found that
                      the artists of Art21 came from various countries. If the
                      subtitles or even some keywords in these artists’ first
                      languages could be added, it might increase awareness among
                      those users.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* section7 */}
            <div className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-2">
              <div className="d-flex justify-content-center text_heading">
                CONTENT ANALYSIS
              </div>
              <div style={{ fontWeight: 400 }} className="text_simple mt-4">
                <span style={{ fontWeight: 700 }}>Finding 4.</span> We identified the
                common attributes of the ten most popular videos and the ten least
                popular videos.
              </div>
              <div className="text_simple mt-4">
                We analyzed 500 videos on the Art21 YouTube channel, from 2018 to the
                present. Nineteen that had more than 10K views were selected as the
                most popular in the channel, and the top ten of those were analyzed in
                our study. These came from the “Extended Play” playlist, which is a
                digital series providing unmediated, behind-the-scenes access to
                today’s leading artists.
              </div>
              <div>
                <img width="2048px" height="198px" className="img-fluid mt-4" src="/PicturesPage2/contentAnalysisc.png" />
              </div>
              <div style={{ fontWeight: 400 }} className="text_simple mt-4">
                <span style={{ fontWeight: 700 }}> Recommendation 4.1.</span> Produce
                more videos about the behind-the-scenes stories.
              </div>
              <div className="text_simple" style={{ lineHeight: '1.2em' }}>
                Art21’s top 10 videos all come from its “Extended Play” series, which
                provides behind-the-scenes stories about leading artists, with high
                engagement and good video performance. More videos uncovering the
                stories behind the screen and reporting provocative ideas could draw
                more attention from viewers and improve their impressions of the
                channel.
              </div>
              <div style={{ fontWeight: 400 }} className="text_simple mt-4">
                <span style={{ fontWeight: 700 }}> Recommendation 4.2.</span> Use strong
                visually thumbnails to affect impressions conversion
              </div>
              <div className="text_simple mt-3" style={{ lineHeight: '1.2em' }}>
                For 9 of the 10 videos, the thumbnail-impressions conversion rate is
                much higher than average. The other video has a much lower conversion
                rate. Because thumbnail selection is so important for popular videos,
                high-quality and attractive thumbnails are highly recommended.
              </div>
            </div>
            {/* section8 */}
            <div className="bg-change">
              <div className="section_body_2 d-flex flex-column mx-auto px-3 px-lg-0">
                <div className="d-flex justify-content-center text_heading">
                  SEO ANALYSIS
                </div>
                <div style={{ fontWeight: 400 }} className="text_simple mt-4">
                  <span style={{ fontWeight: 700 }}>Finding 5.</span>YouTube’s SEO has
                  the potential to increase visibility and rankings for the Art21
                  videos.
                </div>
                <div className="text_simple mt-4">
                  To analyze the Art21 channel’s SEO, we used the following criteria:
                </div>
                <div>
                  <ul className="mt-4 p-0 text_simple" style={{ lineHeight: '1.2em' }}>
                    <li>Videos are categorized</li>
                    <li>Keywords are included naturally in the video title</li>
                    <li>The video’s description includes keywords</li>
                    <li>
                      The video is tagged with popular keywords related to its topic
                    </li>
                    <li>
                      Cards and end screens (calls to action) are included to increase
                      the channel's viewership
                    </li>
                    <li>
                      An eye-catching custom thumbnail image for the video's result
                      links is uploaded
                    </li>
                    <li>Videos are sorted into playlists</li>
                    <li>Video posting is regular and consistent</li>
                  </ul>
                </div>
                <div style={{ fontWeight: 400 }} className="text_simple mt-4">
                  <span style={{ fontWeight: 700 }}> Recommendation 5.1. </span>Make
                  YouTube SEO more effective by using keywords.
                </div>
                <div className="text_simple mt-3">
                  We recommended including more keywords in the video titles,
                  descriptions, and tags. This will help Art21 optimize its
                  performance and its ranking in YouTube’s organic search results for
                  a given query.
                </div>
                <div style={{ fontWeight: 400 }} className="text_simple mt-4">
                  <span style={{ fontWeight: 700 }}> Recommendation 5.2. </span>Increase
                  audience engagement by getting more comments.
                </div>
                <div className="text_simple mt-3">
                  Another way to increase a channel’s popularity is to get more
                  comments. Like Google, YouTube prioritizes popular content, and
                  comments are one of the metrics it uses to decide what people like
                  the most. It’s a good idea to end your video with a question to get
                  a discussion started.
                </div>
              </div>
            </div>
            {/* section7 */}
            <div className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-1">
              <div className="d-flex justify-content-center text_heading">
                BENCHMARKING RESEARCH
              </div>
              <div className="d-flex flex-column align-items-center align-items-md-start flex-md-row justify-content-between">
                <div className="benchmark_width">
                  <div style={{ fontWeight: 400, wordSpacing: '-0.1em' }} className="text_simple mt-4">
                    <span style={{ fontWeight: 700 }}>Finding 6.</span>Art 21's main
                    competitors are The Art Assignment and MoMa.
                  </div>
                  <div className="text_simple mt-5">
                    All three channels focus on art education and artists' creative
                    process. The videos they produce are in English and are released
                    on a regular basis.
                  </div>
                  <div className="text_simple mt-4">
                    <p>
                      MoMA’s YouTube channel was started in 2006, Art21’s in 2007, and
                      The Art Assignment’s in 2013. Art21 had a huge spike in activity
                      from 2006 to 2008 but a decreasing number of uploads in general.
                      The Art Assignment had its only real activity spike from 2013 to
                      2015, and its activity decreased afterward. The MoMa channel is
                      erratic but consistently posts the largest number of videos each
                      year. In average comment counts and average view counts, The Art
                      Assignment performed the best. MoMa had a high record and view
                      counts. Although Art21 had higher records than Art Assignment,
                      its overall view count was lower.
                    </p>
                  </div>
                </div>
                <div>
                  <img width="408px" height="440px" className="img-fluid mt-4" src="/PicturesPage2/BenchmarkImage.png" />
                </div>
              </div>
              <div style={{ fontWeight: 400 }} className="text_simple mt-5 mt-md-0">
                <span style={{ fontWeight: 700 }}> Recommendation 6. </span>Release more
                how-to videos to increase viewers’ curiosity.
              </div>
              <div className="text_simple mt-4" style={{ wordSpacing: '0.1em' }}>
                Many popular videos have questions as their titles, which easily evoke
                viewers’ curiosity. For example, MoMA and The Art Assignment selected
                titles that did very well in catching user’s curiosity, so their views
                are sometimes over one million. We recommend using questions as titles
                or creating the suspense related to the thumbnails to get higher
                engagement.
              </div>
            </div>
            {/* sectionFoteer */}
            <div className="bg-change">
              <div className="section_body_2 d-flex flex-column mx-auto px-4 px-lg-3">
                <div className="text_heading mt-0 mt-xl-3 text-center">REFLECTIONS</div>
                <div className="d-flex justify-content-between flex-column flex-lg-row align-items-center align-items-lg-start mt-4 mt-md-5">
                  <div className="col-12 col-xl-6 text_simple p-0">
                    In this study, our research team used a combination of methods and
                    tools to analyze the performance of the Art21.org website and
                    Art21’sYouTube channel. This approach allowed us to make a deep
                    analysis, so we chose it because we were limited in our access to
                    data. We had one year of data (2018–2019) from YouTube Insights.
                    <p>
                      We didn’t focus only on data analysis; we also paid a lot of
                      attention to content analysis to develop our recommendations. We
                      believe that by implementing our recommendations, Art21 can
                      increase viewers’ engagement, expand its audience, and optimize
                      its video performance to rank higher in search results. Futher
                      key performance indicators monitoring will help to evaluate
                      recommendation effectivnes and determine focus for future work.
                    </p>
                  </div>
                  <div className='mt-3 mt-xl-0'>
                    <img width="474px" height="316px" className="img-fluid" src="/PicturesPage2/Footer.png" />
                  </div>
                </div>
                <div className="d-flex flex-column text_simple mt-3" style={{ fontWeight: 400 }}>
                  <a href="https://lookerstudio.google.com/u/0/reporting/1wVDpzTPUZyRnFBAf0CW1eGYO5-0u3LlA/page/n4m7" target="_blank" style={{ textDecoration: 'underline' }}>Google Data Studio Dashboard</a>
                  <a className="mt-1" target="_blank" href="https://drive.google.com/file/d/1ZZCRGFyaWjQxIucfnLZKYGtrTjRO59rX/view" style={{ textDecoration: 'underline' }}>Final Report</a>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </ChakraProvider>
    )
  }
}
