import React from "react";
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  // IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
  Highlight,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  // Tabs,
  // TabList,
  // Tab,
  // TabPanels,
  // TabPanel,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { AiFillMail } from "react-icons/ai";
import logo54 from "../../11.png";
import { chakra, VisuallyHidden } from "@chakra-ui/react";
import {
  FaInstagram,
  FaMailBulk,
  FaGithub,
  FaLinkedin,
  FaGoogle,
  FAEdicat,
} from "react-icons/fa";
import { MdSchool } from "react-icons/md";

import heroImage from "../../10.png";
import resume from "../../2.png";
import { display } from "@mui/system";
import useWindowDimensions from '../WindowDimensionHook/useWindowDimensions'

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={12}
      h={12}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
const Hero = () => {
  const { currentWindowWidth } = useWindowDimensions();

  return (
    <div className="main-div">
      <Container maxW={"7xl"} isLazy>
        <Stack
          spacing={{ base: 0, md: 0 }}
          px={0}
          py={{ base: 0, lg: 14 }}
          direction={{ base: "column", lg: "row" }}
          justifyContent={"space-between"}
        >
          <Flex
            flex={0.34}
            position={"relative"}
            w={"full"}
            justifyContent={"start"}
          >
            {/* <Blob
              w={"190%"}
              h={"150%"}
              position={"absolute"}
              top={"-20%"}
              left={0}
              zIndex={-1}
            /> */}
            {/* <Box
              position={"relative"}
              height={"350px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            > */}
            <div className="main-div-info">
              <Image
                alt={"Hero Image"}
                // fit={"cover"}
                align={"center"}
                w={"80%"}
                h={"65%"}
                margin={"auto"}
                src={heroImage}
              />
              <br />
              <h1
                className="extra-font-1"
                style={{
                  textAlign: "center",
                  color: "#33b38d",
                  fontWeight: "800",
                  fontFamily: "sans-serif",
                }}
              >
                Elena Korshakova
              </h1>

              <p className="extra-font-2 m-width" style={{ textAlign: "center" }}>
                PhD Candidate at{" "}
                <a
                  href="https://www.stevens.edu/"
                  style={{ color: "#33b38d", textDecoration: "underline" }}
                >
                  Stevens Institute of Technology{" "}
                </a>
              </p>
              <p className="extra-font-2" style={{ textAlign: "center" }}>
                <a
                  href="http://www.healthailab.org/index.html"
                  style={{ color: "#33b38d", textDecoration: "underline" }}
                >
                  Health and AI Lab
                </a>
              </p>
              <div
                style={{
                  display: "flex",
                  fontSize: "17px",
                  margin: "auto",
                  marginTop: "12px",
                }}
              >
                <div style={{ marginRight: "13px" }}>
                  <SocialButton
                    label={"Email"}
                    className="links"
                    href={"http://ekorshak@stevens.edu"}
                    target={"_blank"}
                  >
                    <AiFillMail />
                  </SocialButton>
                </div>
                <div style={{ marginRight: "13px" }}>
                  <SocialButton
                    label={"AiFillGithub"}
                    className="links"
                    href={"https://github.com/ElenaKorshakova"}
                    target={"_blank"}
                  >
                    <FaGithub />
                  </SocialButton>
                </div>
                <div style={{ marginRight: "13px" }}>
                  <SocialButton
                    label={"Instagram"}
                    className="links"
                    href={
                      "https://www.linkedin.com/in/elena-korshakova-255158181/"
                    }
                    target={"_blank"}
                  >
                    <FaLinkedin />
                  </SocialButton>
                </div>
                <div style={{ marginRight: "13px" }}>
                  <SocialButton
                    label={"Instagram"}
                    className="links"
                    href={
                      "https://scholar.google.com/citations?user=gF4kRIsAAAAJ&hl=en"
                    }
                    target={"_blank"}
                  >
                    <img src={logo54} style={{ width: "17px" }} />
                  </SocialButton>
                </div>
              </div>
            </div>
            {/* </Box> */}
          </Flex>
          <Stack flex={0.56}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontFamily={"sans-serif"}
            >
              <Text className="about-me margin-top2">About me</Text>
            </Heading>

            <Text>
              <div className="para-spacing-extra">
                <span style={{ Color: "#999999", textAlign: "justify" }}>
                  {" "}
                  I’m currently a PhD candidate in Computer Science at Stevens
                  Institute of Technology advised by{" "}
                  <a
                    href="http://www.skleinberg.org/"
                    style={{
                      fontFamily: "sans-serif",
                      textDecoration: "underline",
                      color: "#33b38d",
                    }}
                  >
                    Dr. Samantha Kleinberg
                  </a>
                  . My research focuses on the intersection of healthcare,
                  computer science, and human-computer interaction. I’m working
                  towards utilizing the potential of AI to optimize the way
                  people make decisions regarding their health. With my
                  research, I envision a future where individuals can
                  confidently take control of their health and well-being by
                  making well-informed choices about their health using AI
                  technology in their daily lives.
                </span>
              </div>
            </Text>
            <Stack paddingTop={7} direction={{ base: "column", md: "row" }}>
              <div>
                <Text className="text-heading">Research Interests</Text>
                <br />
                <ul style={{ width: "240px", textAlign: "left" }}>
                  {console.log(currentWindowWidth)}

                  <li style={{ textAlign: "left" }}>Decision-making {currentWindowWidth > 500 && <br></br>} in healthcare</li>
                  <br />
                  <li className="width-extra2">Human-computer interaction</li>
                  <br />
                  <li>Explainable AI </li>
                </ul>
              </div>
              <div className="margin-top">
                <Text className="text-heading">Education</Text>
                <br />
                <div className="education-list">
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <MdSchool style={{ fontSize: 35, marginRight: 10 }} />
                    <li>
                      <span style={{ fontWeight: "bolder" }}>
                        PhD in Computer Science, Estimated 2025
                      </span>
                      <br></br> Stevens Institute of Technology
                    </li>
                  </div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <MdSchool style={{ fontSize: 35, marginRight: 10 }} />
                    <li>
                      <span style={{ fontWeight: "bolder" }}>
                        MS in Information Experience Design, 2020
                      </span>{" "}
                      <br></br> Pratt Institute
                    </li>
                  </div>
                  <div style={{ display: "flex", marginBottom: 20 }}>
                    <MdSchool style={{ fontSize: 35, marginRight: 10 }} />
                    <li>
                      <span style={{ fontWeight: "bolder" }}>
                        BS in Business Informatics, 2018
                      </span>
                      <br></br> Financial University{" "}
                    </li>
                  </div>
                </div>
              </div>
              {/* <Popover placement="bottom" isLazy>
                <PopoverTrigger>
                  <Button
                    rounded={"full"}
                    rightIcon={<ChevronDownIcon />}
                    size={"lg"}
                    colorScheme={"red"}
                    fontWeight={"normal"}
                    bgGradient="linear(to-l,#33b38d , #33b38d)"
                    _hover={{ bgGradient: "linear(to-r, #33b38d, #33b38d)" }}
                    w="fit-content"
                  >
                    My Resume
                  </Button>
                </PopoverTrigger>
                <PopoverContent _focus={{ boxShadown: "#f4f4" }} ml={5}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontWeight="bold">
                    My Resume(Click on image to open)
                  </PopoverHeader>
                  <PopoverBody style={{display:'flex',flexDirection:'column'}}>
                    <Image
                      alt={"Hero Image"}
                      fit={"cover"}
                      align={"center"}
                      w={"100%"}
                      h={"100%"}
                      borderRadius={"100%"}
                      src={resume}
                      onClick={() => {
                        window.open(
                          "https://drive.google.com/file/d/1IEos-TQhdmfSijUu3_0j-DiWvTpDOIMj/view"
                        );
                      }}
                    />
                    
                  </PopoverBody>
                  
                </PopoverContent>
              </Popover> */}
              {/* </Button> */}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export default Hero;

const PlayIcon = createIcon({
  displayName: "PlayIcon",
  viewBox: "0 0 58 58",
  d: "M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z",
});

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="blob"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="#FF0080"
      />
    </Icon>
  );
};
