import React from "react";
// import { useDispatch } from "react-redux";
import {
  Badge,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  SimpleGrid,
  Text,
  Container,
  useColorModeValue,
} from "@chakra-ui/react";
import { projects } from "../constants/constants";
import logo1 from "../../4.png";
import logo2 from "../../5.png";
import logo3 from "../../6.png";
import logo4 from "../../7.png";
import { BsGithub } from "react-icons/bs";
import styled from "styled-components";
import { transform } from "framer-motion";

const Projects = ({ post, _id }) => {
  const TagList = styled.ul`
    display: flex;
    padding-left: 10px;
  `;
  const Tag = styled.li`
    padding-left: 12px;
    letter-spacing: 0.1rem;
    list-style: none;
    font-size: 0.7rem;
  `;
  const TitleContent = styled.div`
    text-align: center;
    padding: 0.7rem 10px 0 0.7rem;
    margin: 0 15px 0 15px;
    padding-right: 50%;
    width: 100%;
  `;
  // const Hr = styled.hr`
  //   width: 75px;
  //   height: 4px;
  //   margin: 20px auto;
  //   border: 0;
  //   background: #ff0080;
  // `;
  // const dispatch = useDispatch();
  return (
    <div style={{ backgroundColor: "#f7f7f7", padding: "60px 0" }}>
      <Container maxW={"7xl"} isLazy>
        <div className="container-fluid">
          <div
            py={4}
            className="justify-center"
            style={{ flex: 0.6, display: "flex", alignItems:"start", marginLeft:"-10px",textTransform:"capitalize" }}
          >
            <Heading style={{ alignSelf: "center" }}>
              <Text
                className="main-heading"
                style={{
                  color: "#33b38d",
                  margin: "auto",
                  fontFamily: "Helvetica Neue",
                }}
              >
                UX Projects
              </Text>
            </Heading>
          </div>

          <section
            id="about"
            style={{
              display: "flex",
              flex: 1.4,
              flexDirection: "column",
              fontFamily: "sans-serif",
            }}
          >
            <div className="box-div" style={{ marginTop: 30 }}>
              <a href="/eye-tracking-research">
                <img src={logo2} alt="" className="border-radius" />
              </a>

              <div
                id="projects"
                className="box-text-radius pad-left"
                style={{ backgroundColor: "#FFFFFF", display: "flex" }}
              >
                <div className="box-padding">
                  <h1 style={{ fontSize: "16px", fontWeight: "bolder" }}>
                    Pratt Institute Libraries
                  </h1>
                  <a href="/eye-tracking-research">
                    <h2 style={{ fontSize: "23px", fontWeight: "bolder" }}>
                      Eye-tracking Research
                    </h2>
                  </a>
                  <p
                    className="text-aling"
                    style={{ fontSize: "15px", padding: "20px 0" }}
                  >
                    Enhancing filtering and advanced search navigation for the
                    library website
                  </p>
                  <a
                    href="/eye-tracking-research"
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      fontSize: 14,
                    }}
                  >
                    {" "}
                    Learn More
                  </a>
                  <p style={{ color: "gray", fontSize: "12px" }}>
                    #User Testing #Eye Tracking #Usability
                  </p>
                </div>
              </div>
            </div>

            <div
              className="box-div2"
              style={{ borderRadius: "2%", marginTop: 30 }}
            >
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                }}
                className="pad-left"
              >
                <div className="box-padding">
                  <h1 style={{ fontSize: "16px", fontWeight: "bolder" }}>
                    Art21.org
                  </h1>
                  <a href="/digital-analytics">
                    <h2 style={{ fontSize: "23px", fontWeight: "bolder" }}>
                      Digital Analytics Study{" "}
                    </h2>
                  </a>
                  <p
                    className="text-aling"
                    style={{ fontSize: "15px", padding: "20px 0" }}
                  >
                    Optimizing video performance and increasing users'
                    engagement through digital analytics{" "}
                  </p>
                  <a
                    href="/digital-analytics"
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      fontSize: 14,
                    }}
                  >
                    Learn More
                  </a>
                  <p style={{ color: "gray", fontSize: "12px" }}>
                    #SEO #Digital Analytics #Data Analysis
                  </p>
                </div>
              </div>
              <a href="/digital-analytics">
                <img src={logo3} alt="" className="border-radius2" />
              </a>
            </div>

            <div className="box-div" style={{ marginTop: 30 }}>
              <a href="/mobile-navigation-project">
                <img src={logo4} alt="" className="border-radius" />
              </a>
              <div
                className="box-text-radius pad-right"
                style={{ backgroundColor: "#FFFFFF", display: "flex" }}
              >
                <div className="box-padding2">
                  <h1 style={{ fontSize: "16px", fontWeight: "bolder" }}>
                    Alexander Street Press{" "}
                  </h1>
                  <a href="/mobile-navigation-project">
                    <h2 style={{ fontSize: "23px", fontWeight: "bolder" }}>
                      Mobile Navigation Project
                    </h2>
                  </a>
                  <p
                    className="text-aling"
                    style={{ fontSize: "15px", padding: "20px 0" }}
                  >
                    Creating mobile navigation for educational video content
                    platform
                  </p>
                  <a
                    href="/mobile-navigation-project"
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      fontSize: 14,
                    }}
                  >
                    Learn More
                  </a>
                  <p style={{ color: "gray", fontSize: "12px" }}>
                    #User Research #Mobile App #Prototyping
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Container>
    </div>
  );
};

export default Projects;
