import React from "react";
import { ReactNode } from "react";
import {
  Text,
  Badge,
  Box,
  HStack,
  Flex,
  Container,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  AvatarBadge,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo from '../../1.JPG';
const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4} sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0', zIndex: 100 }}>
      <Container maxW={"7xl"} isLazy>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"} position={"static"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box
              bgGradient="linear(to-l, #33b38d, #33b38d)"
              bgClip="text"
              fontSize="2xl"
              fontWeight="extrabold"
              fontFamily="sans-serif"
            >
              Elena Korshakova
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {/* {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))} */}
              {/* <Link
                px={2}
                spacing={4}
                display={{ base: "none", md: "flex" }}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue("gray.200", "gray.700"),
                }}
                href={"#"}
              >
                Home
              </Link> */}
              <Link
                px={2}
                spacing={4}
                display={{ base: "none", md: "flex" }}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue("gray.200", "gray.700"),
                }}
                href={"/#"}
              >
                About
              </Link>
              <Link
                px={2}
                spacing={4}
                display={{ base: "none", md: "flex" }}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "#33b38d",
                  bg: useColorModeValue("gray.200", "gray.700"),
                }}
                href={"#blog"}
              >
                Publications
              </Link>
              <Link
                px={2}
                spacing={4}
                display={{ base: "none", md: "flex" }}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue("gray.200", "gray.700"),
                }}
                href={"/#experience"}
              >
                Experience
              </Link>
              <Link
                px={2}
                spacing={4}
                display={{ base: "none", md: "flex" }}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue("gray.200", "gray.700"),
                }}
                href={"/#projects"}
              >
                UX Projects
              </Link>
              <Link
                px={2}
                spacing={4}
                display={{ base: "none", md: "flex" }}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue("gray.200", "gray.700"),
                }}
                href="https://drive.google.com/file/d/1RzDp76itHWueydEA2AvWoa9-q0PfkTLy/view?usp=share_link"
              >
                CV
              </Link>
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              {/* <Button onClick={toggleColorMode}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button> */}

              <Menu>

                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      src={
                        logo
                      }

                    />
                  </Center>
                  <br />
                  <Center>
                    <Box>
                      <Text fontWeight="bold">

                        {/* <Badge ml="1" colorScheme="green">
                          New
                        </Badge> */}
                      </Text>
                      <Text fontSize="sm"> Research Software Engineer</Text>
                    </Box>
                  </Center>
                  <br />
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              <Link
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                }}
                href={"/#"}
                onClick={isOpen ? onClose : onOpen}
              >
                About
              </Link>
              <Link
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                }}
                onClick={isOpen ? onClose : onOpen}
                href={"/#blog"}
                >
                  Publications
              </Link>
              <Link
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                }}
                href={"/#experience"}
                onClick={isOpen ? onClose : onOpen}
              >
                Experience
              </Link>
              <Link
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                }}
                onClick={isOpen ? onClose : onOpen}
                href={"/#projects"}
                >
                  UX Projects
              </Link>
              <Link
                href="https://drive.google.com/file/d/1Y_M9B75i7P8KN8D_8UQM6VxVheGScoIa/view?usp=sharing"
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                }}
              >
                CV
              </Link>
            </Stack>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default Navbar;
