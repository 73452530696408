import { ReactNode } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { testimonials } from "../constants/constants";
import { display } from "@mui/system";

import useWindowDimensions from '../WindowDimensionHook/useWindowDimensions'


const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={"h3"} fontSize={"xl"}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};
const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string,
  name: string,
  title: string,
}) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

const TestimonialMain = () => {
  const { currentWindowWidth } = useWindowDimensions();

  return (
    <div style={{ padding: "60px 0" }}>
      <Container maxW={"7xl"} isLazy>
        <div className="container-fluid">
          <div
            py={4}
            className="justify-center"
            style={{ flex: 1.5, display: "flex" }}
          >
            <Heading style={{ alignSelf: "center" }}>
              <Text
                className="main-heading"
                style={{
                  color: "#33b38d",
                  margin: "auto",
                  fontFamily: "Helvetica Neue",
                }}
              >
                Experience
              </Text>
            </Heading>
          </div>
          <div id="experience" className="margin-top2" style={{ flex: 2, display: "flex" }}>
            <section className="reveal section3-container" style={{ flex: 1, }}>
              <div
                className="section3-container-div"
                style={{
                  display: "flex",
                  justifySelf: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    marginTop: currentWindowWidth < 500 ? 22 : 25,
                    borderRadius: 20,
                    border: "1px #33b38d solid",
                    backgroundColor: "#33b38d",
                  }}
                ></div>
                <div
                  style={{ backgroundColor: "#33b38d", alignSelf: "center", height: currentWindowWidth < 500 ? '22%' : '24%' }}
                ></div>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    border: "1px #33b38d solid",
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                ></div>
                <div
                  style={{ backgroundColor: "#33b38d", alignSelf: "center", height: currentWindowWidth < 500 ? '18%' : '22%' }}
                ></div>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 20,
                    border: "1px #33b38d solid",
                    marginTop: 10,
                  }}
                ></div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ padding: '10px 0' }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: currentWindowWidth > 500 ? 0.75 : 1 }}>
                      <p
                        style={{
                          color: "#33b38d",
                          fontWeight: "800",
                          fontFamily: "sans-serif",
                        }}
                      >
                        PhD Research Assistant Advised by{" "}
                        <a
                          href="http://www.skleinberg.org/"
                          style={{
                            color: "#33b38d",
                            fontWeight: "800",
                            fontFamily: "sans-serif",
                            textDecoration: "underline",
                          }}
                        >
                          Dr. Samantha Kleinberg
                        </a>
                      </p>
                      <a
                        href="http://www.healthailab.org/"
                        style={{ textDecoration: "underline" }}
                      >
                        Health & AI lab,
                      </a>{" "}
                      Stevens Institute of Technology

                      {currentWindowWidth < 500 && <p style={{ color: 'grey' }}>
                        01/2021 – PRESENT, Hoboken, NJ
                      </p>}
                    </div>

                    {currentWindowWidth > 500 && <div
                      style={{
                        flex: 0.25,
                        textAlign: "right",
                        marginTop: "5px",
                      }}
                      className="data-text"
                    >
                      <h5>01/2021 – PRESENT</h5>
                      <h6>Hoboken, NJ</h6>
                    </div>}

                  </div>

                  {/* <ul style={{ marginLeft: 15, marginTop: 20 }}>
                    <p>
                      Designing experiments and collecting data about people’s
                      decision-making on Prolific
                    </p>
                    <p>
                      Interpreting and analyzing results using factor analysis,
                      logistic regression, linear trend analysis, etc.
                    </p>
                    <p>
                      Developing and evaluating the effectiviness of
                      decision-making support interventions
                    </p>
                  </ul> */}
                </div>
                <br></br>
                <div style={{ padding: '10px 0' }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: currentWindowWidth > 500 ? 0.75 : 1 }}>
                      <p
                        style={{
                          color: "#33b38d",
                          fontWeight: "800",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Graduate Research Assistant Advised by{" "}
                        <a
                          style={{
                            color: "#33b38d",
                            fontWeight: "800",
                            fontFamily: "sans-serif",
                            textDecoration: "underline",
                          }}
                          href="https://irenelopatovska.wordpress.com/"
                        >
                          Dr. Irene Lopatovska
                        </a>
                      </p>
                      <a
                        href="https://prattdx.org/"
                        style={{ textDecoration: "underline" }}
                      >
                        Center for Digital Experience
                      </a>
                      , Pratt Institute

                      {currentWindowWidth < 500 && <p style={{ color: 'grey' }}>
                        01/2021 – PRESENT, Hoboken, NJ
                      </p>}
                    </div>

                    {currentWindowWidth > 500 &&
                      <div
                        style={{
                          flex: 0.25,
                          textAlign: "right",
                          marginTop: "5px",
                        }}
                        className="data-text"
                      >
                        <h5>02/2019 – 12/2020</h5>
                        <h6>New York, NY</h6>
                      </div>
                    }
                  </div>

                  {/* <ul style={{ marginLeft: 15, marginTop: 20 }}>
                    <p>
                      Prototyped educational mobile app and voice interface for
                      mental health support
                    </p>
                    <p>
                      Conducted eye-tracking usability testing and analyzed data
                      using R
                    </p>
                    <p>Presented projects’ results at the ACM conference</p>
                  </ul> */}
                </div>
                <br></br>
                <div style={{ padding: '10px 0' }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: currentWindowWidth > 500 ? 0.75 : 1 }}>
                      <h2
                        style={{
                          flex: 1,
                          color: "#33b38d",
                          fontWeight: "800",
                          fontFamily: "sans-serif",
                        }}
                      >
                        Web designer
                      </h2>
                      <a
                        href="https://usa.kaspersky.com/"
                        style={{ textDecoration: "underline" }}
                      >
                        Kaspersky Lab
                      </a>
                      {currentWindowWidth < 500 && <p style={{ color: 'grey' }}>
                        01/2021 – PRESENT, Hoboken, NJ
                      </p>}
                    </div>

                    {currentWindowWidth > 500 &&
                      <div
                        style={{
                          flex: 0.25,
                          textAlign: "right",
                          marginTop: "5px",
                        }}
                        className="data-text"
                      >
                        <h5>05/2018 – 01/2019</h5>
                        <h6>Moscow, Russia</h6>
                      </div>
                    }
                  </div>

                  {/* <ul style={{ marginLeft: 15, marginTop: 20 }}>
                    <p>
                      Designed websites and branding materials for retail and
                      wellness companies
                    </p>
                    <p>
                      Conceptualized visuals based on clients’ requirements
                    </p>
                    <p>Developed websites architecture and UX strategies</p>
                  </ul> */}
                </div>
              </div>
            </section>
          </div>
        </div>
        <Box style={{ display: "flex" }} className="container-fluid"></Box>
      </Container>
    </div>
  );
};
export default TestimonialMain;
