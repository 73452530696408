import React, { Component, useEffect } from "react";

import { ChakraProvider, Container, Divider } from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import useWindowDimensions from "../components/WindowDimensionHook/useWindowDimensions";
import { useState } from "react";

const EyeTrackingResearch = () => {
  const { currentWindowWidth } = useWindowDimensions();
  const [isSmallerWindow, setIsSmallerWindow] = useState(false);
  const minimumWindowWidthForLargerLayout = 770;
  useEffect(() => {
    if (currentWindowWidth < minimumWindowWidthForLargerLayout) {
      setIsSmallerWindow(true);
    } else {
      setIsSmallerWindow(false);
    }
  }, [currentWindowWidth]);
  return (
    <ChakraProvider>
      <Navbar />
      <div className="mainDiv mx-auto">
        {/* header */}
        <section className="headerBody">
          <div className="backgroundImage3" />
          <div className="divHeader">
            <h2 className="headerHaeding">PRATT INSTITUTE LIBRARIES</h2>
            <p className="headerSubHeading">Eye-tracking Research</p>
          </div>
        </section>
        {/* section1 */}
        <div className="section_body_1 d-flex flex-column flex-md-row mx-auto align-items-center justify-content-between pe-2 px-lg-0 px-2 px-xl-0 overflow-hidden">
              <div className="col-10 col-md-6 p-0 px-0 px-md-3">
                <div className="text_heading">ABOUT THE PROJECT</div>
                <div style={{ marginLeft: '-2px' }} className="mt-2">
                <img
                  className="img-fluid imgSp"
                  width="370px"
                  height="14px"
                  src="/PicturesPage3/para1.png"
                />
                </div>
                <div className="text_simple mt-2 mt-md-4 pr-0 pr-md-5">
                The mission of the Pratt Institute Libraries is to provide
                outstanding service and access to a resource-rich environment
                that facilitates critical thinking and creative teaching and
                learning in the Pratt community.
                </div>
              </div>
              <div className="pl-0 pl-md-3 mt-4 mt-md-0 col-10 col-md-6">
                <div>
                  <div className="text_heading">PROJECT GOALS</div>
                  <div className="text_simple mt-2">
                  The goal of the study was to understand users' search
                  behavior, navigation patterns, and engagement with the “Ask a
                  librarian” window in order to provide recommendations on how
                  to optimize search process.
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row mt-4 gap-4 w-100">
                  <div className="col-12 col-md-5 text-start p-0">
                    <div className="text_heading">OUTCOMES</div>
                    <div className="text_simple mt-2" style={{ textAlign: 'left' }}>
                    Testing scenario <br /> 8 usability tests Heatmaps &amp;
                    GazePlots Design recommendations
                    </div>
                  </div>
                  <div className="col-12 p-0 px-md-3 mt-3 mt-md-0 col-md-8">
                    <div className="text_heading">MY ROLE</div>
                    <div className="text_simple mt-2 pr-0 pr-md-4">
                    I worked on tasks development, user testing, analysis of
                    results, problem-list preparation, and data visualization.
                    </div>
                  </div>
                </div>
              </div>
            </div>
        {/* section2 */}
        <div className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-0">
          <div className="d-flex justify-content-center text_heading">
            RESEARCH PROCESS
          </div>
          <div
            className="mt-3 text-roboto"
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              fontSize: "18px",
              fontWeight: 400,
            }}
          >
            <div
              style={{ width: "1022px", height: "120px" }}
              className="position-relative mt-2"
            >
              <div
                className="position-absolute text-center"
                style={{
                  top: "55px",
                  width: "125px",
                  left: "10px",
                  lineHeight: "1em",
                }}
              >
                Tasks Development
              </div>
              <div
                className="position-absolute"
                style={{
                  top: "55px",
                  width: "120px",
                  left: "210px",
                  lineHeight: "1em",
                }}
              >
                Participants Recruitment
              </div>
              <div
                className="position-absolute text-center"
                style={{
                  top: "55px",
                  width: "120px",
                  left: "385px",
                  lineHeight: "1em",
                }}
              >
                Metrics Selection
              </div>
              <div
                className="position-absolute text-center"
                style={{
                  top: "55px",
                  width: "120px",
                  left: "570px",
                  lineHeight: "1em",
                }}
              >
                User Testing
              </div>
              <div
                className="position-absolute text-center"
                style={{
                  top: "55px",
                  width: "120px",
                  left: "717px",
                  lineHeight: "1em",
                }}
              >
                Data Analysis
              </div>
              <div
                className="position-absolute text-center"
                style={{
                  top: "55px",
                  width: "175px",
                  left: "850px",
                  lineHeight: "1em",
                }}
              >
                Recommendations Development
              </div>
              <img
                width="100%"
                height=" 34px"
                className="z-0"
                src="/PicturesPage1/Line-removebg-preview.png"
              />
            </div>
          </div>
        </div>
        {/* section3 */}
        <div className="bg-change">
          <div className="section_body_2 px-0 px-md-5 d-flex flex-column mx-auto px-3 px-lg-0">
            <div className="d-flex justify-content-center text_heading">
              METHODOLOGY
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mt-4">
              <div>
                <img
                  width="456px"
                  height="256px"
                  className="img-fluid"
                  src="/PicturesPage3/Methadology.png"
                />
              </div>
              <div className="col-12 mt-4 mt-md-0 col-md-6 ps-0 ps-md-5 mt-3 mt-md-0">
                <div
                  style={{ fontWeight: 700 }}
                  className="text-center text-md-left text_simple"
                >
                  Eye-tracking
                </div>
                <div className="text_simple mt-3">
                  For this study, we used eye-tracking, which helps researchers
                  understand visual attention. In usability research, eye
                  tracking allows one to analyze the complete user experience,
                  even aspects of it that the users can't describe.
                </div>
                <div
                  style={{ fontWeight: 700 }}
                  className="text_simple text-center text-md-left mt-4"
                >
                  How it works
                </div>
                <div className="text_simple mt-3">
                  With eye-tracking, we can detect where users look at a point
                  in time, how long they look at something, and the path their
                  eyes follow.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section4 */}
        <div className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-0">
          <div className="d-flex justify-content-center text_heading">
            TASKS
          </div>
          <div className="mt-0 mt-md-4">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mt-4 md-mt-5">
              <div className="col-12 col-md-6 pe-0 pe-md-0">
                <div
                  style={{ fontWeight: 700 }}
                  className="text_simple text-center text-md-left"
                >
                  Task #1
                </div>
                <div
                  style={{ fontWeight: 400 }}
                  className="text_simple mt-2 text-center text-md-left"
                >
                  Understand users’ search behaviors
                </div>
                <div className="text_simple mt-3 text-justify pr-0 pr-md-5">
                  You want to learn more about Marina Abramovic, a famous
                  performance artist, to explore body art and the possibilities
                  of the mind. Can you find any critics of Marina Abramovic from
                  2012?
                </div>
              </div>
              <div className="col-12 col-md-6 pl-0 pl-md-5 mt-3 mt-md-0 p-3 p-md-0">
                <div
                  style={{ fontWeight: 700 }}
                  className="text_simple text-center text-md-left"
                >
                  Task #2
                </div>
                <div
                  style={{ fontWeight: 400 }}
                  className="text_simple mt-2 text-center text-md-left"
                >
                  Understand how users engage with “Ask a Librarian”
                </div>
                <div className="text_simple mt-3 text-justify pr-0 pr-md-4">
                  You want to donate a book you purchased for your class in the
                  spring semester to Pratt Institute Libraries. How do you find
                  more information on how the donation process works?
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* section5 */}
        <div className="bg-change">
          <div className="section_body_2 d-flex flex-column mx-auto px-3 px-lg-0 justify-content-between">
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between">
              <div className="col-10 col-md-4 ps-0 ps-md-4 d-flex flex-column align-items-center">
                <div className="text_heading">PARTICIPANTS</div>
                <div className="mt-4">
                  <img
                    width="312px"
                    height="210px"
                    className="img-fluid"
                    src="/PicturesPage3/Logo1.png"
                  />
                </div>
                <div style={{ fontWeight: 700 }} className="text_simple">
                  8 Pratt Institute Students
                </div>
                <div className="text_simple text-center">
                  Undergraduate / Graduate
                  <p>Within / Outside School of Information</p>
                </div>
              </div>
              {!isSmallerWindow && (
                <div className="col-10 col-md-7">
                  <div className="text_heading text-center">METRICS</div>
                  <div style={{ overflowX: "hidden" }}>
                    <div
                      className="position-relative text_simple"
                      style={{
                        marginTop: "80px",
                        width: "600px",
                        height: "281px",
                        fontSize: "18px",
                      }}
                    >
                      <div
                        className="position-absolute text-center"
                        style={{ top: "200px", width: "140px", left: "20px" }}
                      >
                        Gaze Recording &amp; User Quotes
                      </div>
                      <div
                        className="position-absolute text-center"
                        style={{ top: "-30px", width: "170px", left: "110px" }}
                      >
                        Heatmap (fixation) <br/> &amp; Gazeplot
                      </div>
                      <div
                        className="position-absolute text-center"
                        style={{ top: "200px", width: "100px", left: "240px" }}
                      >
                        Task<br/> Accuracy
                      </div>
                      <div
                        className="position-absolute text-center"
                        style={{ top: "87px", width: "150px", left: "327px" }}
                      >
                        Easy-Difficulty Rating
                      </div>
                      <div
                        className="position-absolute text-center"
                        style={{ top: "200px", width: "100px", left: "460px" }}
                      >
                        Task<br/> Duration
                      </div>
                      <img
                        width="100%"
                        height="100%"
                        src="/PicturesPage3/Logo2.png"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* section6 */}
        <div className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-0">
          <div className="d-flex justify-content-center text_heading">
            USER BEHAVIOR ANALYSIS
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center mt-5 justify-content-between">
            <div className="d-flex align-items-center flex-column col-10 col-md-4 pe-0 pe-md-5">
              <div>
                <img
                  width="71px"
                  height="71px"
                  className="img-fluid opacity60"
                  src="./PicturesPage3/pic1.png"
                />
              </div>
              <div
                className="text_simple text-center"
                style={{ marginTop: "50px" }}
              >
                Users always start with the most significant keywords
              </div>
            </div>
            <div className="d-flex align-items-center flex-column mt-5 mt-md-0 col-10 col-md-4 px-0 px-md-5">
              <div>
                <img
                  width="60px"
                  height="60px"
                  className="img-fluid opacity60"
                  src="/PicturesPage3/pic2.png"
                />
              </div>
              <div
                className="text_simple text-center"
                style={{ marginTop: "57px" }}
              >
                User are familiar with using advanced search
              </div>
            </div>
            <div className="d-flex align-items-center flex-column mt-5 mt-md-0 col-10 col-md-4 px-0 px-md-4">
              <div>
                <img
                  width="50px"
                  height="50px"
                  className="img-fluid opacity60"
                  src="/PicturesPage3/pic3.png"
                />
              </div>
              <div
                className="text_simple text-center"
                style={{ marginTop: "65px" }}
              >
                Users apply the filters and advanced search interchangeably
              </div>
            </div>
          </div>
        </div>
        {/* section5 */}
        <div className="bg-change">
          <div className="section_body_2 d-flex flex-column mx-auto px-3 px-lg-0">
            <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <div className="d-flex justify-content-center text_heading">
                “ASK A LIBRARIAN” ENGAGEMENT
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Georgia",
                  fontStyle: "italic",
                  textAlign: "center",
                  textDecoration: "none",
                  textTransform: "none",
                  color: "rgba(34, 34, 34, 0.5)",
                  marginBottom: "20px",
                }}
              >
                Mental Model of User
              </div>
            </div>
            <div style={{ overflowX: "auto" }}>
              <div
                style={{
                  width: "1021px",
                  height: "205px",
                  textAlign: "right",
                  fontSize: "18px",
                }}
                className="position-relative text_simple mt-2"
              >
                <div
                  className="position-absolute text-right"
                  style={{ top: "80px", left: "49px",width: "140px" }}
                >
                  Users expect fast responses
                </div>
                <div
                  className="position-absolute text-end"
                  style={{ top: "70px", width: "160px", left: "380px" }}
                >
                  Users prefer to communicate with a real person
                </div>
                <div
                  className="position-absolute text-end"
                  style={{ top: "70px", width: "150px", left: "740px" }}
                >
                  Users expect to receive reliable information
                </div>
                <img
                  width="100%"
                  height="100%"
                  className="z-0"
                  src="/PicturesPage3/3steps.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-0"
          style={{ wordSpacing: "0.4em" }}
        >
          <div className="d-flex justify-content-center text_heading">
            USER COMMENTS
          </div>
          <div className="d-flex justify-content-between flex-md-row flex-column align-items-center mt-4 text_simple">
            <div className="col-12 col-md-6 pr-0 pr-md-5 mr-4">
              <div>
                <div
                  style={{ fontWeight: 700, color: "black" }}
                  className="text-center"
                >
                  Participant #3
                </div>
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(34, 34, 34, 0.5)",
                    wordSpacing: "1px",
                  }}
                  className="mt-3 text-center text_simple"
                >
                  "If I were to donate books, I might use “Ask a Librarian”
                  rather than writing an email because an email might take a
                  long time to get a response"
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 700, color: "black" }}
                  className="text-center mt-4"
                >
                  Participant #6
                </div>
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(34, 34, 34, 0.5)",
                    wordSpacing: "1px",
                  }}
                  className="mt-3 text-center"
                >
                  I notice it will pop up sometimes...actually I don't quite
                  like it. It will hide some content when I search for detailed
                  information"
                </div>
              </div>
              <div>
                <div
                  style={{ fontWeight: 700, color: "black" }}
                  className="text-center mt-4"
                >
                  Participant #8
                </div>
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "rgba(34, 34, 34, 0.5)",
                    wordSpacing: "1px",
                  }}
                  className="mt-3 text-center"
                >
                  “I saw the "Ask a Librarian", but I don't usually use them
                  usually when I see them on a website, so I tend to ignore
                  them.. A lot of the time, I feel like it's machines that are
                  answering my questions. Their answers might not be as accurate
                  as if I just browsed the website myself."
                </div>
              </div>
            </div>
            <div style={{minWidth: "300px",width:"auto",overflow:"hidden"}} className="col-8 width-y col-md-6 mt-4 mt-md-0 ps-0 ps-md-5 overflow-hidden">
              <img
                height="364px"
                style={{overflow:"hidden"}}
                className="z-0 overflow-hidden img-fluid"
                src="/PicturesPage3/last fotter.png"
              />
            </div>
          </div>
        </div>
        <div className="bg-change">
          <div className="section_body_2 d-flex flex-column mx-auto px-lg-0">
            <div>
              <div className="d-flex justify-content-center text_heading">
                PROBLEMS IDENTIFIED
              </div>
            </div>
            <div className="ml-5 mt-4 mr-4 mr-md-5">
              <div className="grid-container text_simple">
                <div className="container">
                  <div className="circle" style={{backgroundColor:"#999999"}}>1</div>
                  <div className="content pl-4 pr-0 pr-md-4 text-justify text-md-left">
                    The "Filter" and "Advanced search" buttons have low
                    discoverability
                  </div>
                </div>
                <div className="container">
                  <div className="circle" style={{backgroundColor:"#CCCCCC"}}>3</div>
                  <div className="content pl-4 pr-0 pr-md-1 text-justify text-md-left">
                  "Ask a Librarian" has low discoverability on the home page during free browsing if it does not pop up
                  </div>
                </div>
                 <div className="container">
                  <div className="circle" style={{backgroundColor:"#B7B7B7"}}>2</div>
                  <div className="content pl-4 pr-0 pr-md-1 text-justify text-md-left">
                  The "Download as PDF" option has low discoverability. All users use icons on the right panel to store files, but icons have weak signifiers
                  </div>
                </div>
                <div className="container">
                  <div className="circle" style={{backgroundColor:"#D9D9D9"}}>4</div>
                  <div className="content pl-4 text-justify text-md-left">
                  "Ask a Librarian" is ignored when it pops up, even when the user is having difficulty performing a task
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section_body_1 d-flex flex-column mx-auto px-3 px-lg-0"
          style={{ wordSpacing: "0.4em" }}
        >
          <div className="text_heading text-center">REFLECTIONS</div>
          <div>
            <ul className="m-0 p-0 px-1 px-md-3 mt-4 text_simple text-roboto">
              <li>
                We also had some technical problems. For instance, we had to use
                the "Screen recording" option instead of the "Web page" to
                conduct our study. For that reason, we were unable to use the
                areas of interest.
              </li>
              <li className="mt-3">
                The task difficulty rating wasn't helpful because users rated
                all their tasks 5 (“easy to complete”), though in fact they
                spent a lot of time actually finding the information they were
                looking for and completing the tasks.
              </li>
              <li className="mt-3">
                For futher research to obtain more accurate results, we need to
                hire more participants because Heatmaps and Gezeplots provide
                researchers with qualitative data.
              </li>
            </ul>
          </div>
          <div
            className="d-flex px-1 px-md-3 flex-column text_simple mt-2"
            style={{ fontWeight: 300 }}
          >
            <a
              href="https://drive.google.com/file/d/1ZFnj1tQfEhhjlh8YSDsP3DTa9WuV5Itl/view"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              View all findings and recommendations
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </ChakraProvider>
  );
};

export default EyeTrackingResearch;
